@import "../../../../Inicialized/variables.scss";

.VentanaMatriculas{
    width: 50%;
    margin: 2vw auto;
}

.panelMatricula{
    width: 18vw;
    margin: auto;
}

.btnMatricula{
    background-color: $Secondary;
    margin-bottom: 2vw;
    height: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.68);
    cursor: pointer;
}

.volverAtras{
    cursor: pointer;
}