
  
$primary: #ffab00;
$primaryDark:#cf8a00;
$primaryTransparent: rgba(255,171,0,0.3);
$primaryGradient: linear-gradient(to right, rgba(243,169,73,1) 0%, rgba(253,128,114,1) 100%);

$Secondary: #5ABA51;
$SecondaryLigth: #8cfcf8d7;

$cursando: #65cf45;
$matriculas: #5e69ff;
$third: #8bdad1;

$gray0: #ebebeb;
$gray1: #cecece;
$gray2: #797979;
$gray3: #444444;

$white1: #fcf5ed;

$alert-color: #cb492a;
$light-black-color: #706e72;
$black-color: #414042;

$blurGeneral: 2px;

