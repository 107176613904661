@import "../../../../Inicialized/variables.scss";

@media screen and (max-width: 767px) {


.VentanaMatriculas{
    width: 80%;
}

.panelMatricula{
    width: 100%;
    margin-top: 8vw;
}

.btnMatricula{
    margin-bottom: 5vw;
    height: 8vw;
}

}