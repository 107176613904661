@import "../../../../Inicialized/variables.scss";

.bitacora{
    width: 80%;
    height: fit-content;
    margin: 0 auto;
}
.bitacora>.volverAtras{
    margin-bottom: 1vw;
}

.bitacora>.filtrosBitacora{
    display: flex;
    margin-top: 1vw;
    justify-content: flex-end;
    align-items: flex-end;
}

.bitacora>.filtrosBitacora>.containerFechas{
    width: 40%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.bitacora>.filtrosBitacora>.inputform{
    border: solid 1px #69a3ba;
    width: 22%;
    margin-left: 0.2vw;
    margin-right: 0.2vw;
    height: 1.8vw;
}

.filtroIdUsuBit{
    flex: 1;
}


.bitacora>.listaBitacora{
    display: flex;
    flex-direction: column;
    margin-top: 2vw;
}

.listaBitacora>.eventoBitacora{
    display: flex;
    border-bottom: solid 1px $Secondary;
    margin-bottom: 1vw;
    justify-content: flex-end;
    align-items: flex-end;
}

.eventoBitacora *{
    margin: 0.5vw 1vw;
}

.eventoBitacora>.txtDescripcion{
    flex: 1;
}

.eventoBitacora>.txtIDUsuario, .eventoBitacora>.txtTipoEvento, .eventoBitacora>.txtFechaHora {
    width: 18%;
}

.containerFechas>.MuiGrid-container {
    flex-wrap: nowrap !important; 
  }
  

