@import "../../../../Inicialized/variables.scss";

.detalleCurso{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
}

.datosCurso{
    display: flex;
    flex-direction: column;
    border-top: solid 1px gray;
    border-bottom: solid 1px gray;
    margin-top: 1vw;
    padding: 1vw;
}

.detalleCurso h3{
    font-size: 1.2vw;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
    color: $primary;
}

.detalleCurso h2{
    font-size: 1.2vw;
    margin-block-start: 0.3em;
    margin-block-end: 0.3em;
}

.estadoCurso{
    display: flex;
    flex-direction: row;
    border-radius: 1vw;
    height: 2em;
    align-items: center;
    background-color: $gray0;
    margin-bottom: 1.9em;
    width: fit-content;
    padding: 0.1vw 1vw;
}

.instructorDetalle{
    display: flex;
    margin-bottom: 2vw;
}

.instructorDetalle{
    border-bottom: solid 1px gray;
}

.txtDetallesInst{
    display: flex;
    flex-direction: column;
}

.listadoAlumnos{
    margin-bottom: 3vw;
}

.listadoAlumnos>.estudiante{
    border-bottom: solid 1px $Secondary;
    border-radius: 0;
    display: flex;
    align-items: center;
}

.listadoAlumnos>.estudiante>.btnEliminar{
    color: $alert-color;
    width: 1.8vw;
    justify-self: end;
    cursor: pointer;
}

.listadoAlumnos>.estudiante>.texto{
    flex: 1;
}

.listadoAlumnos>.estudiante>.iconoNoGraduado{
    color: $gray2;
    cursor: pointer;
}

.listadoAlumnos>.estudiante>.iconoGraduado{
    color: $Secondary;
    cursor: pointer;
}

