@import "./variables.scss";

.CargandoContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cargando{
    margin: auto;
    font-size: 1.2em;
}

.spinnerCargando{
    color: $primary;
    border-color: $primary;
}