@import "../../Inicialized/variables.scss";

.containerPDF{
    width: 50%;
    margin: 2vw auto;
}

.vistaPDF{
    margin-top: 1vw;
    height: 10vw;
}

.vistaPDF *{
    width: 100%;
    height: 50vw;
}