@import "../../Inicialized/variables.scss";

.Login{
    width: 100%;
    flex: 1;
    background-color:  #fff;
    justify-content: center;
    top: 2em;
    position: relative;
    display: flex;
}

.backLogin{
    background-color: $primary;
    text-align: center;
    border-radius: 20px;
    background: $primaryGradient !important;
}

.backLogin h2{
    margin: 0.4vw;
}

.formularioLogin{
    background-color: #fff;
    display: flex;
    width: 20vw;
    height: 20vw;
    border-radius: 0 0 20px 20px;
    justify-content: center;
    -webkit-box-shadow: 0px 4px 10px 0px #474747; 
    box-shadow: 0px 4px 10px 0px #474747;
}

.formularioLogin img{
    position: absolute;
    bottom: 1vw;
    width: 4vw;
}

.formularioLogin form{
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 70%;
}


.formularioLogin form .inputform{
    height: 3em ;
    margin-top: 2em ;
    width: 100% ;
    font-size: 1em;
    border-radius: 7px;
}

.MuiInput-underline:after{
   outline: none;
   left: 0;
   right: 0;
   bottom: 0;
   content: "";
   position: absolute;
   transform: scaleX(0);
   transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
   border-bottom: 2px solid $Secondary !important;
   pointer-events: none;
}


