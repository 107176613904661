@import "../../../../Inicialized/variables.scss";

@media screen and (max-width: 767px) {

    .curso{
        display: flex;
        flex-direction: row;
        border-radius: 1vw;
        align-items: center;
        background-color: $gray0;
        margin-bottom: 0.5em;
        position: relative;
        overflow: hidden;
    
    }
.curso .idCurso{
    align-self: flex-start;
    font-size: 3vw;
}


.curso .nombre{
    flex: 1;
    font-size: 3.5vw;
    height: fit-content;
}



.curso>.codMat{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 2vw 6vw 2vw 2vw;
    font-size: 3vw;
}

.codMat .estado{
    margin-right: 1vw;
    display: contents;
}

.codMat>.codigo{
    margin-right: 0.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
}

.btnaccion{
    display: flex;
    height: 100%;
    position: absolute;
    right: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
}

}