@import "../../../Inicialized/variables.scss";

.menuAdministrativos {
    width: fit-content;
    display: flex;
    margin: auto;
}

.ItemMenuUsu {
    width: 8vw;
    height: 9vw;
    margin: 1vw;
    border-radius: 20px;
    text-align: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    -webkit-box-shadow: 0px 1px 5px 0px #474747; 
    box-shadow: 0px 1px 5px 0px #474747;
}

.ItemMenuUsu img{
    width: 90%;
}

.ItemMenuUsu span{
    font-size: 1.2em;
}

