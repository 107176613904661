@import "../../../../Inicialized/variables.scss";

@media screen and (max-width: 767px) {

.backMatricula{
    padding: 5vw;
    margin-top: 8vw;
}

.matriculado{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.matriculado span{
    text-align: center;
    margin-bottom: 1vw;
}

.matriculado img{
    width: 50%;
}

.codTemp{
    border: solid 1px $primary;
}

.matricular{
    margin-top: 1vw;
}

}